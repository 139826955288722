<template>
  <b-card
    v-if="projects"
    no-body
    class="card-company-table"
  >
    <b-table
      ref="refProjectListTable"
      :items="paginatedProjects"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >
      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`project-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'project-view-id', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="BoxIcon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'project-view-id', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.name,30) }}
          </b-link>
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(reference_id)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.externalReference }}
          </span>
        </b-media>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(end_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(Actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`project-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'project-view-id', params: { id: data.item.id }})"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProjects"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
} from 'bootstrap-vue'
import constants from '@/constants/static.json'
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
  },
  mixins: [mixinDate, mixinList],
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'id',
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: false },
        { key: 'reference_id', label: 'Reference ID', sortable: false },
        { key: 'start_date', label: 'Start Date', sortable: false },
        { key: 'end_date', label: 'End Date', sortable: false },
        { key: 'Actions' },
      ],
    }
  },
  computed: {
    totalProjects() {
      return this.projects.length
    },
    dataMeta() {
      const localItemsCount = this.projects.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProjects,
      }
    },
    paginatedProjects() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.projects.slice(start, end)
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
