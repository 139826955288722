<template>
  <b-card
    v-if="users"
    no-body
    class="card-company-table"
  >
    <b-table
      ref="refUserListTable"
      :items="paginatedUsers"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >
      <!-- Column: Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`user-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'user-profile-id', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="UserIcon"
              />
            </b-avatar></template>
          <b-link
            :to="{ name: 'user-profile-id', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.firstName }}  {{ data.item.lastName }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>
      <!-- Column:-- Role -->
      <template #cell(Role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="mapUserRoleToVariant(data.item.userRoleId).Icon"
            size="18"
            class="mr-50"
            :class="`text-${mapUserRoleToVariant(data.item.userRoleId).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ mapUserRoleToVariant(data.item.userRoleId).name }}</span>
        </div>
      </template>
      <!-- Column: Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <small class="text-muted">{{ data.item.lastLogin ? formatDateTime(data.item.lastLogin) : $t('NEVER_LOGGED') }}</small>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(Actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`user-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.id }})"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
} from 'bootstrap-vue'
import constants from '@/constants/static.json'
import {
  mapUserRoleToVariant,
} from '@/constants/utils'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
  },
  mixins: [mixinDate],
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'id',
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'Name', sortable: false },
        { key: 'Role', sortable: false },
        { key: 'LastLogin', sortable: false },
        { key: 'Actions', sortable: false },
      ],
    }
  },
  computed: {
    totalUsers() {
      return this.users.length
    },
    dataMeta() {
      const localItemsCount = this.users.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.users.slice(start, end)
    },
  },
  setup() {
    return {
      mapUserRoleToVariant,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
