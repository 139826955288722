<template>
  <section id="dashboard-analytics">

    <b-card
      no-body
      class="card-developer-meetup"
    >
      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <feather-icon
              icon="LayoutIcon"
              size="34"
              class="mr-50 text-primary"
            />
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25">
              {{ company.name }}
              <b-badge
                :variant="`light-${mapPackageToVariant(company.packageId).variant}`"
              >
                {{ $t('PACKAGE') }} : {{ mapPackageToVariant(company.packageId).name }}
              </b-badge>
            </b-card-title>
          </div>
        </div>
        <!--/ metting header -->
        <!-- start date -->
        <b-media
          no-body
        >
          <b-media-aside
            v-if="company.addressLine1"
            class="mr-1"
          >
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="MapPinIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body v-if="company.addressLine1">
            <h6 class="mt-0 pt-50">
              {{ company.addressLine1 }} , {{ company.addressLine2 }} , {{ company.postcode }} , {{ company.city }}  , {{ company.country }}
            </h6>
          </b-media-body>
        </b-media>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <b-card-body>
        <b-tabs>
          <b-tab
            :title="$t('Projects')"
            active
          >
            <b-card-text>
              <company-project-view
                :projects="allProjects"
              />
            </b-card-text>
          </b-tab>
          <b-tab
            :title="$t('Contents')"
          >
            <b-card-text>
              <company-content-view
                :contents="allContents"
              />
            </b-card-text>
          </b-tab>
          <b-tab
            :title="$t('USERS')"
          >
            <b-card-text>
              <company-user-view
                :users="allUsers"
              />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  VBTooltip,
  BMediaBody,
  BBadge,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import mixinAlert from '@/constants/mixinAlert'
import { mapPackageToVariant } from '@/constants/utils'
import { mapActions, mapState } from 'vuex'
import CompanyUserView from './CompanyUserView.vue'
import CompanyContentView from './CompanyContentView.vue'
import CompanyProjectView from './CompanyProjectView.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    CompanyUserView,
    CompanyContentView,
    CompanyProjectView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList],
  computed: {
    ...mapState('company', ['company']),
    ...mapState('user', ['allUsers']),
    ...mapState('content', ['allContents']),
    ...mapState('project', ['allProjects']),
  },
  created() {
    const { id } = router.currentRoute.params
    if (id) {
      this.fetchCompanyById(id)
      this.fetchUsersByCompanyID(id)
      this.fetchContentsByCompanyID(id)
      this.fetchProjectByCompanyID(id)
    }
  },
  methods: {
    ...mapActions('company', [
      'fetchCompanyById',
    ]),
    ...mapActions('user', [
      'fetchUsersByCompanyID']),
    ...mapActions('content', [
      'fetchContentsByCompanyID']),
    ...mapActions('project', [
      'fetchProjectByCompanyID']),
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      mapPackageToVariant,
    }
  },

}
</script>
